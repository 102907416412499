<template>
  <div class="app-container platform">
    <div v-scrollTop>
      <div class="page-title">平台管理</div>
      <avue-crud
        ref="crud"
        class="search-form"
        v-model="searchForm"
        :option="tableOption"
        :data="tableData"
        :page="tablePage"
        :tableLoading="tableLoading"
        @size-change="sizeChange"
        @search-change="searchChange"
      >
        <template #menuLeft="scoped">
          <!-- 暂不新增<createPlatform :sup_this="m_this" /> -->
        </template>

        <template #logo="{ row }">
          <div style="position: relative">
            <!-- <el-image v-if="row.logo" style="width: 60px; height: 60px" :src="row.logo" /> -->
            <defaultImg :src="aLiCompressPicByUrl(row.logo, 100)" :content="row.name"></defaultImg>
          </div>
        </template>

        <!-- <template #isImportWaybill="{ row }">
          <div style="position: relative">
            <span v-if="row.isImportWaybill == 1" class="text">是</span>
            <span v-else class="text">否</span>
          </div>
        </template>

        <template #isDockApi="{ row }">
          <div style="position: relative">
            <span v-if="row.isDockApi == 1" class="text">是</span>
            <span v-else class="text">否</span>
          </div>
        </template>  -->

        <template #menu="{ row }">
          <editPlatform :initData="row" :sup_this="m_this" v-p="['externaladmin:system:platform:edit']" />
        </template>
      </avue-crud>
    </div>
  </div>
</template>

<script>
import avueCrud from '@/mixins/avueCrud'
import { tableOption } from './const'
import { deepClone } from '@/components/avue/utils/util'
import { mapGetters } from 'vuex'
import createPlatform from './module/create/index'
import editPlatform from './module/edit/index'
import { getPlatformList as getList } from '@/api/shop/manageApi'

export default {
  mixins: [
    avueCrud({
      getList,
      tableOption
    })
  ],
  components: { createPlatform, editPlatform },

  data() {
    return {
      tableData: [],
      searchForm: {},
      m_this: this
    }
  },
  computed: {},
  watch: {},
  methods: {}
}
</script>

<style lang="scss" scoped>
::v-deep .el-image {
  width: 100px;
  height: 100px;
}
</style>
